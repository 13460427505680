<template>
  <div style="width: 100%;height: 100%" class="searchBoxMap">
    <div id="mapAdd" style="width: 100%;height: 100%">

    </div>
        <location-lengd ref="LocationLengd"></location-lengd>
  </div>
</template>

<script>
import { formatLocation } from '@/views/Gis/utils/geoTool'
import LocationLengd from '@/views/gisbook/locationLengd.vue'
export default {
  components: {
    LocationLengd
  },
  data() {
    return {
      marker:'',
      address:''
    }
  },
  mounted() {
    
  },
  destroyed() {
    console.log(this.map, 'destroy');
    if (this.map) this.map.destroy()
  },
  methods: {
    setMap(obj, lonlat) {
      let position = formatLocation(lonlat, 'Point')
      this.$nextTick(() => {
        console.log(this.map, '------------------1');
        if (this.map) this.map.setCenter(position)
        if (!this.map) {
          this.map = new AMap.Map("mapAdd", {
            resizeEnable: true,
            center: position,
            zoom: 14,
            pitch: 0,
            rotation: 0,
            zoom: 11,
            zooms: [0, 20],
            maxZoom: 20,
            labelzIndex: 1,
            pitchEnable: false,
            rotateEnable: false,
            expandZoomRange: true, //调整最大缩放级数
            showBuildingBlock: false,
            animateEnable: false,
            jogEnable: false,
          });
          this.$refs.LocationLengd.getwms()
        }
        console.log(this.map, '------------------2');
        // return
        if (obj.showType === 'line') {
          if (this.markerHighlightA) {
            this.map.remove(this.markerHighlightA)
          }
          this.markerHighlightA = new AMap.Polyline({
            path: obj.lineArray,
            isOutline: true,
            outlineColor: '#1A5F48',
            borderWeight: 3,
            strokeColor: '#00FF7F',
            strokeOpacity: 1,
            strokeWeight: 3,
            strokeStyle: 'solid',
            lineJoin: 'round',
            lineCap: 'round',
            zIndex: 150,
            extData: ''
          })
          this.map.add(this.markerHighlightA)
        } else {
          if (this.markerHighlight) {
            this.map.remove(this.markerHighlight)
          }
          this.markerHighlight = new AMap.Marker({
            content:
              '<div class="setCommon"><i class="setPoint"></i></div>',
            offset: new AMap.Pixel(0, 0),
            position: position,
            extData: ''
          })
          // this.map.add(this.markerHighlight)
        }
        let that = this
        var geocoder
      AMap.plugin(["AMap.Geocoder"],function (){
      geocoder = new AMap.Geocoder({
          city: "010",
          radius: 500
        });
      })
    that.map.on('click',function(e){
      if(that.marker){
        that.map.remove([that.marker])
      }
        let point =[]
        point[0] = e.lnglat.lng
        point[1] = e.lnglat.lat
        that.lnglat = point
        that.marker = new AMap.Marker({
          position: point,
          map: that.map
        });
        that.map.add([that.marker]);
        geocoder.getAddress(point, function(status, result) {
          if (status === 'complete'&&result.regeocode) {
            var address = result.regeocode.formattedAddress;
            console.log(address,"address")
            that.address = address
            that.$emit('closeModal',that.address,that.lnglat)
          }else{
            console.log('根据经纬度查询地址失败')
          }
        });
        
      })
        // this.openEquipmentInfo(obj, position)
      })
      // this.getList(obj.id)
      // this.stationId = obj.id
      // this.stationName = obj.stationName
    },
    SearchItemClick(e) {
      console.log(e)
      if(this.marker){
        this.map.remove(this.marker)
      }
      // this.map.add([])
      this.map.setCenter([e.location.lng,e.location.lat])
      this.marker  = new AMap.Marker({
        position: [e.location.lng,e.location.lat],
        map: this.map
      });
      this.map.add([this.marker])
    },
  }
}
</script>

<style lang="scss">
.searchBoxMap{
  position: relative;
  
}
.legend{
  bottom: 0.1;
}
</style>